import React from 'react';

const styles = {
	position: 'absolute',
	zIndex: 1,
	top: '50%',
	right: '30px',
	width: '280px',
	height: 'auto',
	transform: 'translateY(-50%)',
	pointerEvents: 'none',
	userSelect: 'none',
	display: 'none',
	'@media (min-width: 768px)': {
		display: 'block'
	},
	'@media (min-width: 992px)': {
		width: '420px'
	},
	'@media (min-width: 1200px)': {
		width: '600px'
	}
};

const NotFoundPageIllustration = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" css={styles}>
		<path
			fill="#20133a"
			opacity="0.6"
			d="M185.27 305.46c51.22 24.18 68.85 72.91 107.63 110.16C327.68 449 378.12 458 424.31 448.06 515.44 428.4 561.58 334.42 560 247.17c-1.65-86.8-47.4-159.27-126.55-195.63-86.8-39.86-185.23-25.9-267.79 19.4-49.44 27.13-90.58 67-85.17 127.58 5.13 57.62 58.51 83.08 104.78 106.94 12.95 6.11-25.04-12.92 0 0z"
		/>
		<circle cx="319.42" cy="277.09" r="89.74" fill="#4eeca5" />
		<path
			fill="#fd476c"
			d="M436.55 144.14a5.32 5.32 0 0 0-1.73-3.72.07.07 0 0 0-.07-.07l-.6-.6-.2-.2-73.65-73.64a4.86 4.86 0 0 0-2.46-1.26c-.2-.07-.4-.07-.6-.13a2.39 2.39 0 0 0-.73-.13h-148.9a5.32 5.32 0 0 0-5.32 5.32v308.42a5.32 5.32 0 0 0 5.32 5.32H431a5.32 5.32 0 0 0 5.32-5.32v-233a5.54 5.54 0 0 0 .27-1zM213 372.81V75h138.26v69.13a5.32 5.32 0 0 0 5.32 5.32h69.13v223.36z"
		/>
		<path
			fill="#391d71"
			d="M292.77 213.81a16 16 0 1 1-3.06-9.37 16.28 16.28 0 0 1 3.06 9.37zM377.79 213.81c0 21.27-31.91 21.27-31.91 0s31.91-21.27 31.91 0M351.26 292a5.32 5.32 0 0 1-5.32-5.32 26.59 26.59 0 0 0-53.18 0 5.32 5.32 0 1 1-10.64 0 37.23 37.23 0 0 1 74.45 0 5.32 5.32 0 0 1-5.31 5.32z"
		/>
		<path
			fill="#4eeca5"
			d="M445.46 389.77a2.21 2.21 0 0 0-3.12 3.12l5.32 5.32a2.28 2.28 0 0 0 1.53.6 2.49 2.49 0 0 0 1.53-.6 2.09 2.09 0 0 0 0-3.12zM462 406.58a2.21 2.21 0 0 0-3.12 3.12l5.32 5.3a2.28 2.28 0 0 0 1.53.6 2.49 2.49 0 0 0 1.53-.6 2.09 2.09 0 0 0 0-3.12zM447.38 406.58l-5.32 5.32a2.09 2.09 0 0 0 0 3.12 2.28 2.28 0 0 0 1.53.6 2.49 2.49 0 0 0 1.53-.6l5.32-5.32a2.09 2.09 0 0 0 0-3.12 1.94 1.94 0 0 0-3.06 0zM464.27 389.77l-5.27 5.31a2.09 2.09 0 0 0 0 3.12 2.28 2.28 0 0 0 1.53.6 2.49 2.49 0 0 0 1.53-.6l5.32-5.32a2.09 2.09 0 0 0 0-3.12c-1-1-2.46-1-3.06 0zM182.89 334.66a10.9 10.9 0 1 0 0 21.8 10.9 10.9 0 1 0 0-21.8zm0 17.15a6.25 6.25 0 1 1 6.25-6.25 6.24 6.24 0 0 1-6.24 6.25z"
		/>
		<path
			fill="#dfe5eb"
			d="M241.85 106.25h2.46v4.45h-2.46v6.18h-5.52v-6.18h-9.44l-.27-3.46 9.71-18.75h5.52zm-10.17 0h4.65v-9.77h-.13l-.27.53zM263.59 105.79c0 3.79-.73 6.65-2.26 8.58a7.72 7.72 0 0 1-12.1 0c-1.53-1.93-2.26-4.85-2.26-8.58v-6.12c0-3.79.73-6.65 2.26-8.58a7.72 7.72 0 0 1 12.1 0c1.53 2 2.26 4.85 2.26 8.58v6.12zm-5.52-6.58a13.11 13.11 0 0 0-.73-5c-.47-1.06-1.13-1.53-2-1.46h-.13c-.86-.07-1.53.4-2 1.46a13.11 13.11 0 0 0-.73 5v7a13.29 13.29 0 0 0 .73 5.05c.47 1.06 1.13 1.53 2 1.46h.13c.86.07 1.53-.4 2-1.46a13.29 13.29 0 0 0 .73-5.05zM281.4 106.25h2.46v4.45h-2.46v6.18h-5.52v-6.18h-9.44l-.27-3.46 9.71-18.68h5.52v17.68zm-10.17 0h4.65v-9.77h-.13l-.27.53z"
		/>
	</svg>
);

export default NotFoundPageIllustration;
