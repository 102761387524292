import React from 'react';
import { graphql } from 'gatsby';

import P from 'components/P';
import H1 from 'components/H1';
import H2 from 'components/H2';
import Hero from 'components/Hero';
import Button from 'components/Button';
import Layout from 'components/Layout';

import HeroIllustration from 'components/SVG/NotFoundPageIllustration';

const NotFoundPage = ({ data, location }) => (
	<Layout
		title="Page Not Found - Tomas.Tech"
		description="The page or file you are looking for does not exist; it may have been moved, or removed altogether."
		canonical={data.site.siteMetadata.siteUrl + '/404'}
		location={location}>
		<Hero illustration={<HeroIllustration />}>
			<H1>That’s embarrassing</H1>
			<H2>The page or file you are looking for does not exist; it may have been moved, or removed altogether.</H2>
			<P>
				<Button isInternal={true} to="/">
					Return to the homepage
				</Button>
			</P>
		</Hero>
	</Layout>
);

export const query = graphql`
	query NotFoundPageQuery {
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;

export default NotFoundPage;
